<template>
    <div class="page-container">
        <transition name="fade" mode="out-in">
            <div v-if="hasData && !isLoading">
                <!-- 備註工具區 Start -->
                <div class="above-card">
                    <div class="all-tips" v-html="showCardNote"></div>
                    <div class="fold-btn" @click="toggleShowAllCards()">
                        {{ getShowAllCardsWord }}
                        <div
                            :class="
                                isShowFoldWording ? 'fold-icon' : 'unfold-icon'
                            "
                        />
                    </div>
                </div>
                <!-- 備註工具區 End -->
                <!-- 卡片 Start -->
                <div
                    v-for="(card, cardIndex) in [...dataset]"
                    :key="cardIndex"
                    :class="getActiveClass(cardIndex)"
                    class="card-wrapper"
                >
                    <div class="card-info" @click="toggleShowData(cardIndex)">
                        <div class="card-title" v-html="card.showTitle" />
                        <div class="toggle">
                            <span>{{ getToggleWord(cardIndex) }}</span>
                            <div class="toggle-menu-icon" />
                        </div>
                    </div>

                    <div class="card-data">
                        <div
                            v-for="(col, colIndex) in docColumns"
                            :key="colIndex"
                            :class="getColumnClass(col)"
                            class="data-cell"
                        >
                            <div class="title" v-html="col.label" />

                            <div
                                class="value"
                                v-html="card[col.key] ?? noValueHtml"
                            />
                        </div>
                        <!-- 保險利益給付表 Start -->
                        <div
                            v-if="docName === 'insuranceInterest'"
                            class="benefit-wrapper"
                        >
                            <div
                                v-for="(col, colIndex) in benefitColumns(card)"
                                :key="colIndex"
                                :class="getColumnClass(col)"
                                class="data-cell"
                            >
                                <div class="title" v-html="col.label" />
                                <div
                                    class="value"
                                    v-html="card[col.key] ?? noValueHtml"
                                    @click="valueHandler(col.key, card)"
                                />
                            </div>
                        </div>

                        <!-- 無有無法辨別資料警示 -->
                        <div
                            v-if="card.hasWarning ?? false"
                            class="data-cell warning"
                            v-text="getWarning"
                        />
                    </div>
                </div>
                <!-- 卡片 Start -->
            </div>
            <div v-else-if="!hasData && !isLoading">
                <NoData emptyMessage="保險公司暫無更新此資訊" />
            </div>
            <div v-else-if="isLoading" class="loading-container">
                <div class="process-loading-icon" />
                <div class="loading-message">讀取中...</div>
            </div>
        </transition>
    </div>
</template>

<script>
import _ from 'lodash'
import md5 from 'js-md5'
import NoData from '@/components/NoData.vue'
import { getTiaSupplierSyncListAPI } from '@/assets/javascripts/api'
import {
    config,
    syncOptions,
    syncDataNote,
    insuranceInterestList
} from '@/assets/javascripts/tia/supplierSync'

export default {
    name: 'SupplierSyncCard',
    emit: ['update:modelValue'],
    components: {
        NoData
    },
    props: {
        docName: {
            type: String,
            default: ''
        },
        insNo: {
            type: [Number, String],
            default: function () {
                return null
            }
        },
        supplierCode: {
            type: [Number, String],
            default: function () {
                return null
            }
        },
        customerId: {
            type: [String, Array],
            default: function () {
                return null
            }
        },
        route: {
            type: String,
            default: ''
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        isShowModal: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getActiveClass: function (cardIndex) {
            return this.isShowData[cardIndex] ? 'active' : ''
        },
        getColumnClass: function (column) {
            const className = {}
            if (column.cell === 1) className.full = true
            else className.half = true
            if (column.isWrap) className.wrap = true
            className[column.key] = true
            return className
        },
        getToggleWord: function (cardIndex) {
            return this.isShowData[cardIndex] ? '點擊收闔' : '點擊展開'
        },
        toggleShowData: function (cardIndex) {
            const toggle = !this.isShowData[cardIndex]
            this.isShowData[cardIndex] = toggle
            const order = cardIndex == '0' ? 'First' : 'Other'
            this.$setGaEvent(
                `isShowSync${order}Data${toggle ? 'True' : 'False'}`,
                'click-Icon',
                {
                    isModal: true
                }
            ) //GA
        },
        getSupplierSyncList: async function () {
            try {
                this.isLoading = true
                const payload =
                    this.docName === 'customerBySupplier'
                        ? {
                              ids: this.customerId,
                              supplierCode: this.supplierCode
                          }
                        : {
                              supplierCode: this.supplierCode,
                              insNo: this.insNo
                          }
                const res = await getTiaSupplierSyncListAPI(
                    this.docName,
                    payload
                )
                this.listData = res?.data
            } catch (error) {
                this.$showErrorMessage(
                    '查詢時發生錯誤，請稍後再試',
                    '如持續發生此情形，請聯絡資訊客服'
                )
            } finally {
                this.isShowData = { 0: true }
                this.isLoading = false
            }
        },
        testIsDate: function (dateString) {
            // 僅適用於 YYYY-MM-DD HH:MM:SS.mmm*格式，在保險公司對接資料夠用，其他地方不一定
            return /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9].[0-9][0-9][0-9]/g.test(
                dateString
            )
        },
        transformDataNormal: function (dataset) {
            return dataset.map((data) => {
                // 主檔、附約商品名稱進行處理
                if (data.productName) {
                    const isMain = data.isMainContract
                    const productIcon = isMain
                        ? 'main-product-icon'
                        : 'attached-product-icon'
                    data.productName = `
                        <div class="product">
                            <div class="${productIcon} icon"></div>
                            ${data.productName}(${data.productCode})
                        </div>`
                }

                //保障明細表給付項目
                if (data.insuredItems) {
                    const insuredItems = data.insuredItems
                    let insuredItem = ''
                    insuredItems.forEach((item) => {
                        insuredItem += `<div class="item flex">
                            <div class="payItem">${item.payItem}</div>
                            <div class="payAmount">${this.$numberWithComma(
                                item.payAmount
                            )}${item.payUnit}</div>
                        </div>`
                    })
                    data.insuredItems = insuredItem
                }
                //保單利益給付表單欄位顯示
                if (data.amountDetailKey && data.amountDetailList) {
                    if (data.amountDetailKey.length > 1)
                        data.benefitList = `開啟新視窗檢視<div class="new-window-icon"/>`
                    else {
                        const list = _.cloneDeep(data.amountDetailList)
                        const min = Math.min(
                            ...list.map((item) => item.insYear)
                        )
                        const max = Math.max(
                            ...list.map((item) => item.insYear)
                        )
                        data.insYear = `${min} ~ ${max} 年`
                        data.insYearRemark = '本保單年度區間內，豁免保費有效'
                    }
                }

                // datetime格式只留日期
                Object.keys(data).forEach((key) => {
                    if (this.testIsDate(data[key])) {
                        data[key] = data[key].substring(0, 10)
                    }
                    //data空字串視同無資料顯示wording
                    if (data[key] === '') data[key] = this.noValueHtml
                })

                this.docColumns.forEach((col) => {
                    // 藍色標頭顯示
                    if (col?.isTitle) {
                        this.upDateInfo = col.label
                        data.showTitle = `${col.label}: ${data[col.key]}`
                    }
                    //數值加千分位
                    if (typeof data[col.key] === 'number') {
                        data[col.key] = this.$numberWithComma(data[col.key])
                    }
                })
                // 保額加上單位
                if (data.insAmount) {
                    data.insAmount = `${data.insAmount}${data.amountUnit ?? ''}`
                }

                return data
            })
        },
        toggleShowAllCards: function () {
            let showDataList = {}
            const _this = this

            this.dataset.forEach(function (data, index) {
                if (_this.isShowFoldWording) showDataList[`${index}`] = false
                else showDataList[`${index}`] = true
            })
            this.isShowData = showDataList
            this.isShowFoldWording = !this.isShowFoldWording
        },
        benefitColumns: function (card) {
            return insuranceInterestList.filter(function (col) {
                if (card.benefitList) return col.key === 'benefitList'
                else return col.key !== 'benefitList'
            })
        },
        valueHandler: function (key, card) {
            if (key === 'benefitList') {
                let routeData = this.$router.resolve(
                    this.getBenefitLink(card.productCode)
                )
                window.open(routeData.href, '_blank')
            } else return
        },
        saveBenefitData: function () {
            const payload = this.listData.reduce((memo, data) => {
                if (!data.productCode) return memo
                memo[data.productCode] = {
                    productName: `${data.productName}(${data.productCode})`,
                    amountDetailKey: data.amountDetailKey,
                    amountDetailList: data.amountDetailList
                }
                return memo
            }, {})
            // 保單號碼為個資，為避免直接暴露在網址列(可能被存在瀏覽紀錄)，需經過Hash
            const hashedInsNo = md5(this.insNo)

            this.$store.commit('set', {
                supplierSyncBenefit: {
                    ...this.$store.state.supplierSyncBenefit,
                    [hashedInsNo]: payload
                }
            })
        },
        getBenefitLink: function (productCode) {
            const hashedInsNo = md5(this.insNo)
            return {
                name: 'TiaSupplierBenefit',
                params: {
                    insNo: hashedInsNo,
                    id: productCode
                }
            }
        }
    },
    computed: {
        dataset: function () {
            let dataset = _.cloneDeep(this.listData)
            return this.transformDataNormal(dataset)
        },
        docColumns: function () {
            return this.supplierSyncConfig[this.docName] ?? []
        },
        hasData: function () {
            if (this.listData.length > 0) return true
            else if (Object?.keys(this.listData).length > 0) return true
            else return false
        },
        infoTips: function () {
            let docName
            if (this.docName === 'insNotify') docName = '保單通知'
            if (this.docName === 'note') docName = '照會'
            return `為此保單全部的「${docName}」更新 `
        },
        getDocNote: function () {
            let countNote = `依${this.upDateInfo}排序，`
            let dataNote = []
            syncOptions[this.route].forEach((doc) => {
                if (doc.value === this.docName) {
                    //每個檔的更新頻率和次數備註
                    if (typeof doc.amount === 'number') {
                        if (doc.amount === 1) {
                            countNote = '顯示最近1次更新紀錄'
                        } else countNote += `顯示最近${doc.amount}次更新紀錄`
                    }
                    if (doc.amount === 'N') countNote += `顯示所有更新紀錄`
                    if (doc.amountNote) countNote = doc.amountNote
                    //每個檔各別的備註
                    if (doc.note) dataNote = doc.note
                }
            })
            //保險公司對接功能全檔備註->syncDataNote.forAll

            return [countNote, ...syncDataNote.forAll, ...dataNote]
        },
        showCardNote: function () {
            let content = _.cloneDeep(syncDataNote.forSome)
            let showList = _.cloneDeep(syncDataNote.forSome)
            let noteKeyList = Object.keys(syncDataNote.forSome)

            //所有要顯示的備註key轉成array清單->備註key:對應標題key
            noteKeyList.forEach((key) => {
                showList[key] = []
            })

            //取得每個檔案config裡note屬性有備註清單key的標題名稱
            let titleLabel = ''
            this.docColumns
                .filter((col) => col.note)
                .forEach(function (col) {
                    noteKeyList.forEach((key) => {
                        if (col.note?.includes(key)) {
                            // note屬性如有備註清單的key，加入要顯示的清單
                            showList[key].push(col.key)
                            // 要顯示的備註內容前面加上標題名稱
                            titleLabel += `「${col.label}」`
                            content[key] = `${titleLabel}${content[key]}`
                        }
                    })
                })

            //在config裡note屬性沒有"syncDataNote"裡的key，移除此備註文字
            noteKeyList.forEach((tip) => {
                if (
                    typeof showList[`${tip}`] === 'string' ||
                    showList[`${tip}`].length < 1
                ) {
                    delete content[`${tip}`]
                }
            })
            //檔案的備註轉為html
            let showNote = ''
            this.getDocNote.forEach((note) => {
                showNote += `<p>・<span>${note}</span></p>`
            })

            //檔案裡各項資料標題的備註
            Object.values(content).forEach((value) => {
                showNote += `<p>・<span>${value}</span><p/>`
            })

            return showNote
        },
        getShowAllCardsWord: function () {
            return this.isShowFoldWording ? '全部收闔' : '全部展開'
        },
        noValueHtml: function () {
            return `<div class="no-info">(保險公司暫未提供)</div>`
        },
        getWarning: function () {
            const name = syncOptions[this.route]?.find(
                (doc) => doc?.value === this.docName
            )
            return `*此${name?.label}內容可能含有無法辨識的內容，如有疑問請洽經代窗口或飛鴿表單`
        }
    },
    watch: {
        docName: {
            async handler(newVal) {
                if (!newVal) return
                this.listData = []
                await this.getSupplierSyncList()

                // 將保險利益給付表存起來，到分頁開啟
                if (newVal === 'insuranceInterest') {
                    this.saveBenefitData()
                }
            }
        },
        isLoading: function () {
            this.$emit('update:modelValue', this.isLoading)
        },
        isShowModal: function () {
            this.listData = []
            if (this.supplierCode && this.isShowModal)
                this.getSupplierSyncList()
        },
        isShowData: {
            handler() {
                if (Object.values(this.isShowData).includes(true))
                    this.isShowFoldWording = true
                else this.isShowFoldWording = false
            },
            deep: true
        }
    },
    data() {
        return {
            supplierSyncConfig: config,
            isShowData: {
                0: true
            },
            isShowFoldWording: true,
            listData: [],
            isLoading: false,
            upDateInfo: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.above-card {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .all-tips {
        :deep(p) {
            display: flex;
            color: $secondary-grey;
            font-size: 12px;
            margin: 0;
            padding: 0;
            span {
                display: inline-block;
            }
        }
    }
    .fold-btn {
        margin-top: 10px;
        padding-left: 10px;
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        color: $secondary-grey;
        margin-left: auto;
        cursor: pointer;

        .fold-icon,
        .unfold-icon {
            padding-left: 5px;
            margin-bottom: 3px;
            width: 12px;
            height: 12px;
        }
    }
}
:deep(.item) {
    justify-content: space-between;
    padding: 5px 0;
    .payAmount {
        margin-left: 15px;
        word-break: keep-all;
    }
}
:deep(.benefitList) {
    .value {
        cursor: pointer;
        color: $primary-blue;
        text-decoration: underline;
        .new-window-icon {
            display: inline-block;
            margin: 0 0 -2px 5px;
            width: 16px;
            height: 16px;
            background-color: $primary-blue;
        }
    }
}

.card-wrapper {
    border-radius: 3px;
    background-color: $primary-white;

    &:not(:last-of-type) {
        margin-bottom: 20px;
    }

    .card-info {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        width: 100%;
        min-height: 66px;
        border-radius: 3px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        padding: 10px 20px;
        box-sizing: border-box;
        color: $sixth-black;
        font-size: 16px;
        font-weight: 500;
        .card-title {
            display: flex;
            align-items: center;
            white-space: nowrap;
            :deep(.product) {
                display: flex;
                margin-left: 5px;
                white-space: normal;
                align-items: center;
                width: fit-content;
                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
                .icon {
                    margin-right: 5px;
                    height: 20px;
                    min-width: 20px;
                }
            }
        }

        @media screen and (max-width: 576px) {
            display: flex;
            padding: 10px 15px;
            font-size: 14px;
            div {
                width: max-content;
            }
        }

        .toggle {
            display: flex;
            align-items: center;
            margin-left: 10px;
            cursor: pointer;
            span {
                display: inline;
                white-space: nowrap;
                @media screen and (max-width: 576px) {
                    display: none;
                }
            }

            .toggle-menu-icon {
                height: 15px;
                width: 15px;
                margin-left: 10px;
                transition: transform 0.3s;
                transform: rotate(0deg);
            }
        }
    }

    .card-data {
        padding: 0 20px 0 20px;
        box-sizing: border-box;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        max-height: 0px;
        overflow: hidden;
        transition: max-height 500ms cubic-bezier(0, 1, 0, 1),
            padding 300ms ease 200ms;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .data-cell {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            font-size: 14px;
            color: $eighth-black;

            @media screen and (max-width: 576px) {
                justify-content: space-between;
                :deep(.product-title) {
                    display: none;
                }
            }
            @media screen and (max-width: 374px) {
                display: block;
                text-align: left;
            }

            :deep(.title) {
                width: 125px;
                min-width: 125px;
                color: $placeholder-black;
            }

            :deep(.value) {
                text-align: left;
                .no-info {
                    opacity: 0.4;
                }
                @media (min-width: 375px) and (max-width: 576px) {
                    text-align: right;
                }
                @media screen and (max-width: 374px) {
                    text-align: left;
                }
            }

            :deep(.product) {
                display: flex;
                flex-wrap: nowrap;
                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
                .icon {
                    margin-right: 5px;
                    height: 20px;
                    min-width: 20px;
                }
            }

            &.full {
                width: 100%;
            }

            &.half {
                width: 50%;

                @media screen and (max-width: 576px) {
                    width: 100%;
                }
            }
            &.wrap {
                display: block;
                .value {
                    text-align: left;
                }
            }

            :deep(&.insuredItems) {
                padding-top: 10px;
                border-top: 1px dashed $fourth-grey;
            }
        }
        //金額明細表
        .benefit-wrapper {
            width: 100%;
            border-top: 1px dashed $fourth-grey;
            padding-top: 10px;
        }

        //無法辨識內容警示
        .warning {
            margin-top: 10px;
            color: $secondary-grey;
            font-size: 12px;
        }
    }

    &.active {
        .card-info {
            background-color: $third-white;

            .toggle-menu-icon {
                transform: rotate(90deg);
            }
        }

        .card-data {
            max-height: 3000px;
            padding: 15px 20px 15px 20px;
            transition: max-height 500ms ease-in-out, padding 300ms ease;
        }
    }
}

:deep(.main-product-icon),
:deep(.attached-product-icon) {
    width: 20px;
    height: 20px;
    border-radius: 3px;
}

.loading-container {
    display: block;
    justify-content: center;
    width: 100%;
    padding: 100px 0 0 0;
}

.process-loading-icon {
    width: 120px;
    height: 120px;
}

.process-loading-icon {
    border: 8px solid $third-grey;
    border-top: 8px solid $primary-blue;
    width: 50px;
    height: 50px;
    margin: auto;
}

.loading-message {
    font-size: 15px;
    color: $secondary-grey;
    text-align: center;
    margin-top: 20px;
}

@media screen and(max-height:576px) {
    .loading-container {
        padding: 15px 0 0 0;
        .loading-message {
            margin-top: 5px;
        }
    }
}
</style>
